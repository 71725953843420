:root {
  --color-white: #f6f6f6;
  --color-dark: #282828;
  --color-light: #dedede;
  --color-lighter: #eee;
  --color-light-2: #e2e2e2;
  --color-light-3: #d2dde2;
  --color-text: #737373;
  --color-accent: #327594;
  --color-accent-lighter: #3b7691;

  --color-green: #495a48;
  --color-red: #d07373;
  --color-red-2: #9f4848;
  --color-orange: #9f6e48;

  --color-savings: #96b195;
  --color-expenses: #9fb7cc;
  --color-installments: #84a4bd;
  --color-subscriptions: #668399;
  --color-others: #5b92bd;

  --color-gwenduling: #deba4e;

  --padding: 15px;

  // toast
  --toastify-color-light: var(--color-light);
  --toastify-color-info: var(--color-accent);
  --toastify-color-success: var(--color-green);
  --toastify-color-warning: var(--color-orange);
  --toastify-color-error: var(--color-red);
}

:root .body-root._dark {
  --color-white: #262525;
  --color-dark: #dfdfdf;
  --color-light: #323131;
  --color-lighter: #2c2a2a;
  --color-light-2: #413b3b;
  --color-light-3: #435158;
  --color-text: #f8f8f8;

  --toastify-color-dark: var(--color-light);
}
