.installments-table._table {
  ._row.-done {
    background-color: var(--color-light-2);

    ._cell {
      text-decoration: line-through;
    }
  }
  ._row ._cell {
    &.-dates {
      flex-shrink: 0;
      flex-basis: 250px;
    }

    &.-date {
      flex-shrink: 0;
      flex-basis: 100px;
    }

    &.-progress {
      flex-shrink: 0;
      flex-basis: 80px;
    }

    &.-amount {
      > span {
        display: flex;
      }
      flex-shrink: 0;
      flex-basis: 90px;
    }

    &.-buttons {
      flex-basis: 150px;
    }

    &.-date-inputs {
      display: flex;
      flex-shrink: 0;
      flex-basis: 500px;

      > * {
        margin-right: 5px;
      }

      > .total-months {
        align-self: center;
        flex-basis: 80px;
        flex-shrink: 0;
        font-size: 14px;
        color: var(--color-accent);
      }
    }

    @media (max-width: 1100px) {
      &.-dates {
        flex-basis: 200px;
      }
    }
  }

  ._row.add-instalment ._cell {
    &.-buttons {
      flex-basis: 90px;
    }
  }
}
