.sheet-component {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .headings {
    display: flex;
    > *:first-child {
      flex-grow: 1;
    }
  }
}
.sheet-flex {
  flex-grow: 1;
  overflow: hidden;
  .left {
    padding-bottom: var(--padding);
    display: flex;
    flex-direction: column;
    flex-basis: 280px;
    flex-shrink: 0;
    margin-right: var(--padding);
  }

  .right {
    flex-grow: 1;
  }

  .left,
  .right {
    overflow: auto;
  }
}

.sheet-footer {
  display: flex;
  flex-shrink: 0;
  margin-top: var(--padding);
  padding: 5px;
  background-color: var(--color-light);
}
