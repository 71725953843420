.nav-component {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);
  overflow: auto;

  .item {
    margin-bottom: 30px;
    transition: all 0.3s ease-in;

    &:hover {
      filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));
    }

    &.-bottom {
      margin-top: auto;
    }
  }
}
