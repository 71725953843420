.summary-component {
  margin-top: var(--padding);
  margin-bottom: var(--padding);
}

.summary-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  hr {
    width: 100%;
  }

  .summary {
    display: flex;
    align-items: center;
    .label {
      margin-right: auto;
    }

    .percentage {
      margin-right: 2px;
      font-size: 13px;
      opacity: 0.4;
    }

    .amount {
      color: var(--color-accent);

      &.-highlight {
        font-weight: 800;
      }
    }
  }
}
