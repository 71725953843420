.months-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--padding);
  .month {
    padding: var(--padding);
    border: 1px solid var(--color-light);
    border-radius: 8px;

    .label {
      font-weight: 700;
      color: var(--color-accent);
    }

    &.-current {
      -webkit-box-shadow: 1px 1px 3px 2px rgba(184, 184, 184, 0.3);
      -moz-box-shadow: 1px 1px 3px 2px rgba(184, 184, 184, 0.3);
      box-shadow: 1px 1px 3px 2px rgba(184, 184, 184, 0.3);
    }

    &.-prev {
      background-color: var(--color-lighter);
    }
  }
}

.entities-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .entity {
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 2px 12px;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text);
    border: 1px solid var(--color-text);
    border-radius: 7px;
    transition: all 0.3s ease-in;

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-white);
    }
  }
}

.filter-container {
  display: flex;
  margin-bottom: 10px;
}
