.header-component {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 60px;
  position: relative;

  .title {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-text);
  }

  .demo {
    position: absolute;
    left: 0px;
    top: 38px;
    padding: 1px 2px;
    font-size: 11px;
    font-weight: 600;
    color: var(--color-light);
    background-color: var(--color-accent);
  }

  .author {
    margin-top: 4px;
    font-size: 14px;
    color: var(--color-text);
    a {
      text-decoration: underline;
      &:hover {
        color: var(--color-gwenduling);
      }
    }
  }
}
