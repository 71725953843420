.saved-expenses-component {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.saved-expenses-left {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 148px);
}

.saved-expenses {
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: var(--padding);
  overflow: auto;
}
