.templates-list-component {
  margin-top: var(--padding);
}

.templates-list {
  margin-bottom: var(--padding);
  .template {
    display: flex;
    padding: 6px 5px;
    align-items: center;
    border-bottom: 1px solid var(--color-lighter);

    &.-active,
    &:hover {
      background-color: var(--color-light-3);
    }

    &.-inactive {
      .label {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    > .label {
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: var(--color-dark);
    }
  }
}
