.legend-container {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &.-slim {
    grid-template-columns: repeat(2, 1fr);
  }

  .legend {
    display: flex;
    margin-bottom: 10px;

    .name {
      margin-left: 5px;
      font-size: 13px;
    }

    .color {
      width: 15px;
      height: 15px;

      &.-savings {
        background-color: var(--color-savings);
      }

      &.-expenses {
        background-color: var(--color-expenses);
      }

      &.-installments {
        background-color: var(--color-installments);
      }

      &.-subscriptions {
        background-color: var(--color-subscriptions);
      }

      &.-others {
        background-color: var(--color-others);
      }
    }
  }
}
