.sheets-component {
  overflow: hidden;
}

.sheets-flex {
  height: calc(100vh - 199px);
  overflow: hidden;
  ._grow {
    overflow: auto;
  }
}

.sheets-footer {
  display: flex;
  flex-shrink: 0;
  margin-top: var(--padding);
  padding: 5px;
  background-color: var(--color-light);
}
