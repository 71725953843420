.device-limit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent);
  color: var(--color-light);
  z-index: 1;

  @media (max-width: 1000px) {
    display: flex;
  }

  @media (max-height: 600px) {
    display: flex;
  }
}
