.body-root {
  display: flex;
  height: 100vh;
  position: relative;
  background-color: var(--color-light);
  color: var(--color-text);

  > .left {
    flex-basis: 80px;
    flex-shrink: 0;
  }

  > .right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    .body-main {
      display: flex;
      flex-direction: column;
      padding: var(--padding);
      flex-grow: 1;
      background-color: var(--color-white);
      border-top-left-radius: 15px;
      border-bottom-left-radius: 5px;
      overflow: auto;

      > *:first-child {
        flex-grow: 1;
      }

      > *:nth-child(2) {
        margin-top: auto;
      }
    }
  }
}
