.expenses-wrapper {
  border-radius: 15px;
  position: relative;

  ._row {
    &.-checked {
      background-color: var(--color-light);
      .-text {
        text-decoration: line-through;
      }
    }

    &.-template {
      background-color: var(--color-light-3);
    }

    &.-installment {
      background-color: var(--color-light-2);
    }
  }

  ._cell {
    margin-left: 5px;
    text-align: left;

    &.-total {
      position: absolute;
      right: var(--padding);
      > .value {
        font-weight: 800;
        color: var(--color-accent);
      }
    }

    &.-amount {
      > span {
        display: flex;
      }
      flex-shrink: 0;
      flex-basis: 120px;
    }

    &.-expense {
      .installment-data {
        margin-left: 5px;
        font-size: 12px;
        align-self: center;
        color: var(--color-accent);
      }
    }

    &.-type {
      margin: 0;
      flex-shrink: 0;
      flex-basis: 10px;
      align-self: stretch;
      cursor: pointer;
      .type {
        width: 10px;
        min-height: 30px;
        height: 100%;
        &.-expenses {
          background-color: var(--color-expenses);
        }

        &.-savings {
          background-color: var(--color-savings);
        }

        &.-installment {
          background-color: var(--color-installments);
        }

        &.-others {
          background-color: var(--color-others);
        }

        &.-subscriptions {
          background-color: var(--color-subscriptions);
        }
      }
    }

    &.-text {
      display: flex;
      position: relative;
    }
  }
}

.expenses-type {
  height: 100%;
  .react-contexify__item {
    .react-contexify__item__content {
      font-weight: 400;
      color: var(--color-text);
    }

    .color {
      position: absolute;
      right: 0;
      width: 10px;
      height: 30px;

      &.-expenses {
        background-color: var(--color-expenses);
      }

      &.-savings {
        background-color: var(--color-savings);
      }

      &.-installment {
        background-color: var(--color-installments);
      }

      &.-others {
        background-color: var(--color-others);
      }

      &.-subscriptions {
        background-color: var(--color-subscriptions);
      }
    }
  }
}
