.sheets-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: var(--padding);
  margin-bottom: var(--padding);
  .sheet {
    border-bottom: 2px solid var(--color-accent);
    .expenses-component {
      max-height: 250px;
      min-height: 200px;
      overflow: auto;
    }
  }
}

.sheets-buttons {
  display: flex;
  align-items: center;
}
