.earning-item {
  display: flex;
  > &:not(:last-child) {
    margin-bottom: 5px;
  }

  .amount {
    font-weight: 300;
    color: var(--color-accent);
  }

  .delete {
    margin-left: auto;
    font-size: 12px;
    cursor: pointer;
  }
}

.input-container {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;

  > .input {
    display: flex;
    align-items: baseline;

    &:nth-child(2) {
      margin-left: var(--padding);
    }
  }
}
