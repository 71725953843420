.auto-complete-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 1;
}

.auto-complete {
  position: absolute;
  top: 24px;
  left: 0;
  height: 200px;
  width: 400px;
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  z-index: 3;
  overflow: hidden;

  .options-list {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 200px;
    padding: 2px;
    overflow: auto;

    .empty {
      margin-top: 10px;
      text-align: center;
    }

    .option-item {
      display: flex;
      padding: 5px 10px;
      border-bottom: 1px solid var(--color-light);
      color: var(--color-text);
      cursor: pointer;

      &.-active,
      &:hover {
        background-color: var(--color-accent);
        color: var(--color-white);
      }

      .name {
        font-size: 16px;
      }

      .type {
        flex-shrink: 0;
        margin-left: auto;
        font-size: 13px;
      }
    }
  }
}
