.footer-component {
  display: flex;
  padding-top: 10px;
  border-top: 1px solid var(--color-accent);
  color: var(--color-text);

  p {
    font-size: 11px;
  }
}
