.sheets-heading {
  display: flex;

  .sheet-title {
    flex-grow: 1;
    align-self: center;
    margin-left: var(--padding);
    margin-bottom: var(--padding);
    border-bottom: 2px solid var(--color-accent);
  }
}
