._shadow {
  margin-left: 1px;
  -webkit-box-shadow: 2px 2px 5px 4px rgba(184, 184, 184, 1);
  -moz-box-shadow: 2px 2px 5px 4px rgba(184, 184, 184, 1);
  box-shadow: 2px 2px 5px 4px rgba(184, 184, 184, 1);
}

._padding-top {
  padding-top: var(--padding);
}

._left-auto {
  margin-left: auto;
}

._top-auto {
  margin-top: auto;
}

._margin-left {
  margin-left: var(--padding);
}

._margin-right {
  margin-right: var(--padding);
}

._margin-top {
  margin-top: var(--padding);
}

._margin-bottom {
  margin-bottom: var(--padding);
}

._flex {
  display: flex;
}

._page-flex {
  display: flex;
  > ._grow {
    flex-grow: 1;
  }

  > ._fixed {
    flex-basis: 280px;
    flex-shrink: 0;
  }
}

._page-height {
  height: calc(100vh - 166px);
  overflow: auto;
}

._w-100 {
  width: 100%;
}

._center {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

._bold {
  font-weight: 800;
}

._error-message {
  text-align: right;
  font-size: 13px;
  color: var(--color-red);
}

._empty {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

._input {
  display: block;
  width: 100%;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid var(--color-light);
  border-bottom-width: 2px;
  border-radius: 5px;
  background-color: var(--color-light);
  color: var(--color-text);

  &.-error {
    border-bottom-color: var(--color-red);
  }
}

._clearbutton {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  > img {
    cursor: pointer;
  }

  &.-disabled {
    opacity: 0.7;
    cursor: not-allowed;
    > img {
      cursor: not-allowed;
    }
  }
}

._clearinput {
  font-size: 15px;
  font-weight: 200;
  font-family: "Work Sans", sans-serif;
  border: 0;
  background-color: transparent;
  color: var(--color-text);
  width: 100%;

  &.-error {
    border-bottom: 2px solid var(--color-red);
  }
}

._clearselect {
  height: 23px;
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  &.-error {
    border-bottom: 2px solid var(--color-red);
  }
}

._button-group {
  display: flex;

  > button {
    margin-right: 5px;
    padding: 4px 6px;
    font-size: 12px;
    background-color: var(--color-white);
    color: var(--color-text);
    border-radius: 3px;

    &.-active {
      background-color: var(--color-lighter);
      cursor: not-allowed;

      > * {
        cursor: not-allowed;
      }
    }
  }
}

._table {
  border-radius: 15px;
  position: relative;

  ._row {
    display: flex;
    width: 100%;
    font-weight: 200;
    align-items: center;
    position: relative;

    &:not(.-header):not(.-footer):not(.-message) {
      min-height: 30px;
      border-left: 1px solid var(--color-light);
      border-right: 1px solid var(--color-light);
      border-bottom: 1px solid var(--color-light);
    }

    &.-header {
      height: 40px;
      text-transform: uppercase;
      font-size: 13px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid var(--color-light);
    }

    &.-message {
      display: flex;
      align-items: center;
      height: 16px;
      min-height: 16px;
      background-color: var(--color-accent);
      > * {
        margin-right: auto;
        margin-left: var(--padding);
        font-size: 11px;
        color: var(--color-white);

        &.-bold {
          font-weight: 600;
        }
      }

      &.-error {
        background-color: var(--color-red-2);
        > * {
          color: var(--color-light);
        }
      }

      &.-warn {
        background-color: var(--color-orange);
      }
    }

    &.-empty {
      height: 180px;
      background-image: linear-gradient(
        to bottom,
        var(--color-light) 1px,
        transparent 1px
      );

      background-size: 29px 29px;
      background-position: 0 -1px;
    }

    ._cell {
      margin-left: 5px;
      text-align: left;

      &.-checkbox {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        flex-basis: 70px;
      }

      &.-buttons {
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
        margin-left: auto;
        flex-shrink: 0;
        flex-basis: 80px;

        > *:not(:last-child) {
          margin-right: 5px;
        }
      }

      &.-text {
        display: flex;
        position: relative;
      }

      &.-total {
        position: absolute;
        right: var(--padding);
        > .value {
          font-weight: 800;
          color: var(--color-accent);
        }
      }

      &.-expense {
        > span {
          display: flex;
        }

        flex-grow: 1;
        flex-shrink: 1;
        white-space: wrap;
      }
    }
  }
}
