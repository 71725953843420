.browser-limit {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent);
  color: var(--color-light);
  z-index: 1;

  .title {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }
}
