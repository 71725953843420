body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
    word-break: break-word;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 300;
}

a {
  color: unset;
  text-decoration: none;
  font-weight: 300;
}

input,
button,
select {
  &:focus {
    outline: 0;
  }
  &:focus:not(.-error) {
    box-shadow: 0 4px 2px -2px var(--color-accent);
  }
}

select {
  cursor: pointer;
}

h1 {
  margin: 0;
}

h2 {
  display: inline-block;
}

/* overrides */

.react-toggle .react-toggle-track,
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-white);
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
.react-toggle--checked .react-toggle-track {
  background-color: var(--color-orange);
}

.react-toggle--checked .react-toggle-thumb {
  border-color: var(--color-orange);
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-right-color: var(--color-accent);
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: var(--color-accent);
}

.rc-tooltip-inner {
  padding: 3px 5px;
  min-height: auto;
  color: var(--color-white);
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.react-contexify {
  padding: 0 !important;
  font-size: 14px;
  box-shadow: none !important;
  background-color: var(--color-lighter) !important;

  .react-contexify__item:not(.react-contexify__item--disabled):hover
    > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus
    > .react-contexify__item__content {
    background-color: var(--color-accent);
  }
}
