.button {
  margin-right: 5px;
  font-weight: 700;
  border: 1px solid var(--color-accent);
  color: var(--color-light);
  background-color: var(--color-accent);
  border-radius: 4px;
  cursor: pointer;

  &.-dark {
    color: var(--color-text);
  }

  a {
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    font-weight: 700;
  }

  &.-active,
  &:hover {
    &:not(.-disabled) {
      background-color: var(--color-accent-lighter);
    }
  }

  &.-block {
    width: 100%;
  }

  &.-disabled {
    cursor: not-allowed;
  }

  &.-slim {
    font-size: 12px;
    a {
      padding: 5px 10px;
    }

    &.-padding {
      padding: 5px 10px;
    }
  }

  &.-padding {
    padding: 10px 15px;
  }
}
